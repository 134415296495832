<template>
    <div class="card card-default" :class="loading ? 'whirl' : ''">
        <div class="card-header bg-gray-lighter text-bold">{{$t('common.view.settings.devices.heading')}}</div>
        <div class="card-body">
            {{$t('common.view.settings.devices.description')}}

            <ul class="timeline-alt mt-4">
                <li v-for="device in user.devices">
                    <div class="timeline-badge primary">
                        <em v-show="!device.is_mobile" class="fad fa-desktop"></em>
                        <em v-show="device.is_mobile" class="fad fa-mobile"></em>
                    </div>
                    <div class="timeline-card">
                        <div class="popover left">
                            <h4 class="popover-header">{{ device.first_seen_at | moment('utc', 'calendar')}}</h4>
                            <div class="popover-body">
                                <p>{{ device.device_name }} | IP: {{ device.ip_address }} | Platform: {{device.platform}} | Browser: {{ device.browser }}| Country: {{ device.country }}
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    export default {
        components: {},
        data() {
            return {
                user:{
                    devices: [],
                },
                langs: ['en', 'de'],
                langsFull: [this.$t('common.view.settings.language.english'),this.$t('common.view.settings.language.german')],
                loading: false,
                settings: {
                    language: 'en'
                }
            }
        },
        methods: {
            loadDevices() {
                this.loading = true;
                this.$api.get('user/devices').then((response) => {
                    this.user.devices = response.data;
                    this.loading = false;
                });
            },
        },
        mounted() {
            this.loadDevices();
        }
    }
</script>